<template>
  <div>
    <div class="mb-3">
      <label
        for="step-text"
        class="mb-2"
      >
        Шаг: {{ formatStepType(step.type) }}
      </label>
      <div>
        <Editor
          id="step-text"
          v-model:model-value="step.text"
          :height="editorHeight"
          placeholder="Новый шаг"
        />
        <div v-if="this.step.type === 'VideoStep'">
          <div class="form-check form-switch mt-4 mb-0">
            <input
              id="text-transcript"
              v-model="step.display_text_transcript"
              class="form-check-input"
              type="checkbox"
            />
            <label
              class="form-check-label"
              for="text-transcript"
            >
              Текстовый конспект к уроку
            </label>
          </div>
          <small class="text-secondary translate-hint">
            Скроем под кнопкой “Конспект”.
          </small>
          <div
            v-if="step.display_text_transcript"
            class="mt-3"
          >
            <Editor
              id="step-transcript"
              v-model:model-value="step.text_transcript"
              :height="editorHeight"
              placeholder="Расшифровка видео"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="mb-3">
      <input
        v-model.lazy="step.duration"
        type="number"
        class="form-control w-25"
        placeholder="Длительность в минутах"
      />
    </div>

    <StepAttemptsLimit
      v-if="hasAttemptsLimit"
      v-model:model-value="step.attempts_limit"
      class="mb-3"
    />

    <component
      :is="dataComponent"
      v-if="dataComponent"
      :step="step"
    />
  </div>
</template>

<script>
import { formatStepType } from '@/custom_functions/custom_functions';
import StepAttemptsLimit from '@/components/lessons/StepAttemptsLimit.vue';
import TextTaskStep from '@/components/lessons/TextTaskStep.vue';
import InteractStep from '@/components/lessons/InteractStep.vue';
import QuizStep from '@/components/lessons/QuizStep.vue';
import SortStep from '@/components/lessons/SortStep.vue';
import MatchStep from '@/components/lessons/MatchStep.vue';

export default {
  name: 'StepContent',

  components: {
    StepAttemptsLimit,
    TextTaskStep,
    InteractStep,
    QuizStep,
    SortStep,
    MatchStep,
  },

  props: { step: { type: Object, required: true } },

  computed: {
    editorHeight() {
      if (
        this.step.type === 'TextStep' ||
        this.step.type === 'VideoStep' ||
        this.step.type === 'DeferredStep'
      )
        return 500;
      else return 400;
    },

    hasAttemptsLimit() {
      return this.step.type !== 'TextStep' && this.step.type !== 'VideoStep';
    },

    dataComponent() {
      if (
        this.step.type === 'TextTaskStep' ||
        this.step.type === 'InteractStep' ||
        this.step.type === 'QuizStep' ||
        this.step.type === 'SortStep' ||
        this.step.type === 'MatchStep'
      )
        return this.step.type;
      return null;
    },
  },

  methods: { formatStepType },
};
</script>

<style>
.translate-hint {
  margin-left: 40px;
}
</style>
